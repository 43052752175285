/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: 张喜琴
 * @LastEditTime: 2024-05-06
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { formStatus, getType } from '@/utils/options';
import { orderListStatus } from '@/utils/options';
/**
                                                    * columns ： 对应vue界面的表格属性
                                                    *    label： 表格cloumn 的名称
                                                    *    type： index 索引 ， selection 多选框 值可配置
                                                    *    width： 表格的宽度
                                                    *    kname： 对应列的属性值， vue 表格中template #kname 定义时可用插槽
                                                    *    component: { 控件，在表单中展示图片信息
                                                           name: 'c-image'
                                                         }
                                                    * searchOption： 对应vue界面的搜索表单控件
                                                    */

// export const sampleReceivingStatus = [
// 	{
// 		label: '待提交',
// 		value: 0,
// 	},
// 	{
// 		label: '已提交',
// 		value: 1,
// 	},
// 	{
// 		label: '已驳回',
// 		value: 2,
// 	},
// ];
export var sampleReceivingStatus = [
{
  label: '待提交',
  value: 0 },

{
  label: '已提交',
  value: 1 },

{
  label: '检测中',
  value: 3 },

{
  label: '已驳回',
  value: 2 }];



export var columns = function columns(ctx) {return [
  {
    label: '序号',
    kname: 'index',
    width: 100 },

  {
    label: '合同名称',
    kname: 'contrName' },

  {
    label: '合同编号',
    kname: 'contrNum' },

  {
    label: '客户',
    kname: 'custName' },

  {
    label: '销售',
    kname: 'saleName' },

  {
    label: '快递单号',
    kname: 'pkgNum' },

  {
    label: '样本数',
    kname: 'samples' },

  {
    label: '下单时间',
    kname: 'createAt' },

  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};



export var searchOption = [
{
  kname: 'contrName',
  label: '合同名称：',
  component: {
    name: 'el-input',
    props: {
      maxlength: 30 } } },



{
  kname: 'contrNum',
  label: '合同编号：',
  component: {
    name: 'el-input',
    props: {
      maxlength: 30 } } },



{
  kname: 'custName',
  label: '客户：',
  component: {
    name: 'el-input',
    props: {
      maxlength: 30 } } },



{
  kname: 'saleName',
  label: '销售：',
  component: {
    name: 'el-input',
    props: {
      maxlength: 30 } } },



{
  kname: 'pkgNum',
  label: '快递单号：',
  component: {
    name: 'el-input',
    props: {
      maxlength: 30 } } },



{
  kname: 'startTime', // 开始时间和结束时间使用插槽进行定义，通过kname属性值来识别
  label: '' },

{
  kname: 'endTime', // 开始时间和结束时间使用插槽进行定义，通过kname属性值来识别
  label: '' }];