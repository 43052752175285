var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("c-tab", {
            attrs: { options: _vm.sampleReceivingStatus },
            on: {
              change: function($event) {
                return _vm.getTabData(_vm.currectVal)
              }
            },
            model: {
              value: _vm.currectVal,
              callback: function($$v) {
                _vm.currectVal = $$v
              },
              expression: "currectVal"
            }
          }),
          _c(
            "c-box",
            {
              attrs: {
                flex: "row",
                justify: "space-between",
                margin: "10px 0 0 0"
              }
            },
            [
              _c(
                "c-search",
                {
                  attrs: { option: _vm.searchOption },
                  on: { submit: _vm.getData },
                  scopedSlots: _vm._u([
                    {
                      key: "startTime",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-form-item",
                            _vm._b(
                              { attrs: { label: "开始时间：" } },
                              "el-form-item",
                              scope.data.itemProps,
                              false
                            ),
                            [
                              _c(
                                "el-date-picker",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticStyle: { width: "220px" },
                                      attrs: {
                                        size: "small",
                                        format: "yyyy-MM-dd",
                                        placeholder: "请选择开始日期",
                                        "picker-options": _vm.startTime,
                                        type: "date",
                                        "value-format": "yyyy-MM-dd 00:00:00"
                                      },
                                      model: {
                                        value: _vm.query.startTime,
                                        callback: function($$v) {
                                          _vm.$set(_vm.query, "startTime", $$v)
                                        },
                                        expression: "query.startTime"
                                      }
                                    },
                                    "el-date-picker",
                                    _vm.$attrs,
                                    false
                                  ),
                                  _vm.$listeners
                                )
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "endTime",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-form-item",
                            _vm._b(
                              { attrs: { label: "结束时间：" } },
                              "el-form-item",
                              scope.data.itemProps,
                              false
                            ),
                            [
                              _c(
                                "el-date-picker",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticStyle: { width: "220px" },
                                      attrs: {
                                        size: "small",
                                        format: "yyyy-MM-dd",
                                        placeholder: "请选择结束日期",
                                        "picker-options": _vm.endTime,
                                        type: "date",
                                        "value-format": "yyyy-MM-dd 23:59:59"
                                      },
                                      model: {
                                        value: _vm.query.endTime,
                                        callback: function($$v) {
                                          _vm.$set(_vm.query, "endTime", $$v)
                                        },
                                        expression: "query.endTime"
                                      }
                                    },
                                    "el-date-picker",
                                    _vm.$attrs,
                                    false
                                  ),
                                  _vm.$listeners
                                )
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.query,
                    callback: function($$v) {
                      _vm.query = $$v
                    },
                    expression: "query"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            "native-type": "submit",
                            size: "small",
                            type: "primary"
                          }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  ),
                  _c("el-form-item")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("c-table", {
        attrs: {
          columns: _vm.columns,
          data: _vm.list,
          border: "",
          size: "mini",
          stripe: ""
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      scope.$index + 1 + (_vm.page.page - 1) * _vm.page.pageSize
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "btns",
            fn: function(scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.handleDetail(scope.row)
                      }
                    }
                  },
                  [_vm._v("查看详情")]
                )
              ]
            }
          }
        ])
      }),
      _c("c-pagination", {
        attrs: { slot: "footer", page: _vm.page, search: _vm.getData },
        slot: "footer"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }